var app = {
	windowLoaded: function () {
		_main = this;
		_main.preloader();
	},
	windowResized: function () {
		_main = this;
		_main.getViewport();
	},
	windowScrolled: function () {
		_main = this;
	},
	preloader: function() {
		if ($('.preloader').length) {
			$('.preloader').fadeOut(350, function() {
				$(this).remove();
			});
		}
	},
	cookies: {		
		createCookie: function(name, value, days) {		    
		    _main=app;
		    var date = new Date();
		    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
		    var expires = "; expires=" + date.toGMTString();
		    document.cookie = name + "=" + value + expires + "; path=/";
		    (_main.debug)?console.log('creating cookie: ',name):'';
		},
		readCookie: function(name) {
		    _main=app;
		    var nameEQ = name + "=";
		    var ca = document.cookie.split(';');
		    for (var i = 0; i < ca.length; i++) {
		    	var c = ca[i];
		    	while (c.charAt(0) == ' ') c = c.substring(1, c.length);
		    	if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
		    }
		    return null;
		},
		checkCookies: function() {
		    _main=app;
		    if (_main.cookies.readCookie(_main.cookieName) != 1) {
		      (_main.debug)?console.log('cookie %s is not created yet',_main.cookieName):'';
		      _main.cookies.notification('show');
		    } else {
		    	(_main.debug)?console.log('cookie %s already exists',_main.cookieName):'';
		    }
		    $('.closeNotification').on('click',function () {
		      _main.cookies.createCookie(_main.cookieName, 1, 365);
		      _main.cookies.notification('hide');
		    });
		},
		notification: function(state) {
			_main=app;
			switch (state) {
				case 'show':
					$('footer').append('<div class="cookiesNotification"><p>Na stronie używamy plików cookies.</p><p class="closeNotification">OK</p></div>');
				break;
				case 'hide':					
					$('.cookiesNotification').fadeOut(300, function () {
				        $(this).remove();
				      });
				break;
			}
		}
	},	
	helpers: {
		killInterval: function(whichOne) {
			clearInterval(whichOne);
		},
		getRandom: function(min,max) {
			var number = min + Math.floor(Math.random() * max);
			return number;
		}
	},
	handleSlider: function () {
		_main = this;
		if ($('.slider-hero').length) {
			$('.slider-hero').not('.slick-initialized').slick({
				fade: true,
				autoplay: true,
				autoplaySpeed: 3000,
				pauseOnHover: false,
				pauseOnFocus: false
			});
		}
		if (_main.viewport == 'mobile' && $('body').hasClass('home')) {			
			// $('ul.products').eq(1).slick({				
			// 	arrows: false
			// });
			// $('ul.products').slick({				
			// 	arrows: false
			// });
			// var myCarousel = $("ul.products");
			// myCarousel.each(function() {        
			// 	$(this).not('.slick-initialized').slick({					
			// 		arrows: false
			// 	});
			// }); 
		}
	},
	events: function () {
		_main = this;		

		$('.woocommerce-product-gallery__image a').on('click', function(e) {
			e.preventDefault();
		});
		$('.filter-widget').on('click', function() {
			$(this).toggleClass('active');
			$('.widget_product_categories').toggleClass('active');
		});

	},
	getViewport: function () {
		_main = this;
		wh = $(window).width();
		if (wh <= _main.breakpointMobile) {
			_main.viewport = 'mobile';			
		} else if (wh > _main.breakpointMobile && wh <= _main.breakpointTablet) {
			_main.viewport = 'tablet';
		} else {
			_main.viewport = 'desktop';			
		}
		_main.handleSlider();
		(_main.debug) ? console.log('viewport: ', _main.viewport) : '';
	},
	init: function () {
		_main = this;
		_main.getViewport();
		_main.events();				
		_main.handleSlider();				
		_main.cookies.checkCookies();
	},

	debug: true,
	viewport: null,
	globalNavState: false,
	breakpointMobile: 640,
	breakpointTablet: 1100,
	cookieName: 'enp_www'

}

$(document).ready(function () {
	app.init();
});

$(window).on('load', function () {
	app.windowLoaded();
});

$(window).resize(function () {
	app.windowResized();
});

$(document).scroll(function () {
	app.windowScrolled();
});

console.log('update 2020-03-16 20:47');
